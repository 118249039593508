import React from "react";
import "./WhiteWaveBottom.css";

const WhiteWaveBottom = () => {
  return (
    <div className="white_wave_bottom">
      <div className="picture"></div>
    </div>
  );
};

export default WhiteWaveBottom;
