import React from "react";

function Table() {
  return (
    <div className="hol_picture">
      <svg
        width="999"
        height="773"
        viewBox="0 0 999 773"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_special_event_4aj8 1" clipPath="url(#clip0)">
          <g id="lights">
            <path
              id="Vector"
              d="M443.438 175.718C402.348 175.75 361.543 168.901 322.715 155.454C270.663 137.457 228.021 112.633 195.973 81.672C135.352 23.1061 85.6518 14.2799 54.6396 17.1862C21.0693 20.3336 1.52882 37.5241 1.33546 37.6974L0 36.2086C0.19726 36.0314 20.1406 18.4508 54.3232 15.2072C74.3687 13.3063 95.3311 16.6183 116.63 25.0543C143.19 35.5743 170.353 54.1393 197.363 80.2335C229.199 110.991 271.594 135.663 323.369 153.563C418.416 186.424 525.538 178.468 617.268 131.734C647.639 116.259 677.656 105.697 706.485 100.34C742.839 93.5832 777.315 95.1515 808.965 104.998C878.514 126.635 926.019 125.488 953.623 120.717C983.407 115.569 996.921 105.267 997.054 105.163L998.282 106.742C997.723 107.177 984.236 117.456 953.963 122.688C926.141 127.498 878.296 128.663 808.371 106.907C737.57 84.883 665.862 109.219 618.176 133.515C572.837 156.737 523.33 170.704 472.539 174.6C462.804 175.345 453.103 175.718 443.438 175.718Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_2"
              d="M439.4 200.535C450.594 200.535 459.668 191.461 459.668 180.268C459.668 169.074 450.594 160 439.4 160C428.207 160 419.133 169.074 419.133 180.268C419.133 191.461 428.207 200.535 439.4 200.535Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_3"
              d="M753.4 119.535C764.594 119.535 773.668 110.461 773.668 99.2677C773.668 88.0742 764.594 79 753.4 79C742.207 79 733.133 88.0742 733.133 99.2677C733.133 110.461 742.207 119.535 753.4 119.535Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_4"
              d="M100.4 40.5354C111.594 40.5354 120.668 31.4613 120.668 20.2677C120.668 9.07417 111.594 0 100.4 0C89.2067 0 80.1325 9.07417 80.1325 20.2677C80.1325 31.4613 89.2067 40.5354 100.4 40.5354Z"
              fill="#4BBADF"
            />
          </g>
          <g id="people">
            <path
              id="Vector_5"
              d="M584.744 484.482V680.309H615.534V501.725H738.696V680.309H769.486V288.655H738.696C728.493 288.655 712.832 341.794 712.832 395.19C712.832 435.438 717.532 469.93 724.216 484.482H584.744ZM738.696 484.482H738.396C738.497 484.263 738.596 484.032 738.696 483.804V484.482Z"
              fill="#7A5217"
            />
            <path
              id="Vector_6"
              d="M698.845 191.728H698.845C673.571 191.728 653.083 214.466 653.083 242.515V278.15H664.043L670.388 264.947L668.801 278.15H739.248L745.016 266.147L743.574 278.15H751.506V250.17C751.506 217.894 727.929 191.728 698.845 191.728Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_7"
              d="M646.41 424.554L618.312 436.496L520.672 515.872C517.082 520.284 514.882 525.662 514.35 531.326C513.648 540.457 484.145 588.224 494.682 594.546C505.218 600.868 543.15 619.834 545.258 607.19C547.365 594.546 558.604 540.457 558.604 540.457L652.732 456.164L646.41 424.554Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_8"
              d="M536.126 596.653L524.184 631.775C524.184 631.775 536.828 645.122 530.506 656.361V694.995H520.672L517.862 674.624C517.862 674.624 506.661 694.738 501.004 694.995C489.144 695.535 465.658 699.753 464.955 693.431C464.253 687.109 482.74 680.946 482.74 680.946L502.409 626.858L510.838 593.843L536.126 596.653Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_9"
              d="M719.464 415.422C719.464 415.422 725.786 477.237 707.522 488.477C689.259 499.716 608.478 535.54 608.478 535.54C608.478 535.54 642.897 567.853 635.873 575.58C628.848 583.307 597.238 604.38 593.024 600.165C588.809 595.951 541.745 545.375 547.365 529.218C552.984 513.062 630.956 460.379 630.956 460.379L640.088 427.364L642.897 400.671L672.4 381.705L719.464 415.422Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_10"
              d="M687.281 271.501C703.454 271.501 716.565 258.391 716.565 242.218C716.565 226.045 703.454 212.934 687.281 212.934C671.108 212.934 657.998 226.045 657.998 242.218C657.998 258.391 671.108 271.501 687.281 271.501Z"
              fill="#FFB9B9"
            />
            <path
              id="Vector_11"
              d="M709.981 254.212C709.981 254.212 708.576 280.905 730.352 289.334C752.127 297.763 676.263 352.554 676.263 352.554V291.441C676.263 291.441 686.098 280.904 676.263 264.748L709.981 254.212Z"
              fill="#FFB9B9"
            />
            <path
              id="Vector_12"
              d="M756.693 379.598L751.074 406.993L734.215 501.121C734.215 501.121 748.264 541.16 729.298 540.457C710.332 539.755 719.464 496.906 719.464 496.906L729.298 418.935V388.73L756.693 379.598Z"
              fill="#FFB9B9"
            />
            <path
              id="Vector_13"
              d="M635.873 366.251L629.551 390.837L600.751 460.379C600.751 460.379 581.082 493.394 597.238 492.691C613.395 491.989 613.395 460.379 613.395 460.379L646.41 404.183V372.573L635.873 366.251Z"
              fill="#FFB9B9"
            />
            <path
              id="Vector_14"
              d="M630.956 571.365L654.136 593.141C654.136 593.141 670.293 589.629 676.615 608.594C682.937 627.56 687.151 639.502 687.151 639.502L678.722 644.419L668.185 629.668C668.185 629.668 661.863 656.361 655.541 658.468C649.219 660.575 617.609 674.624 620.419 658.468L635.873 635.99C635.873 635.99 637.98 610.702 631.658 608.594C625.336 606.487 608.478 585.414 608.478 585.414L630.956 571.365Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_15"
              d="M720.577 437.198C720.577 437.198 654.548 413.315 650.333 415.422C647.196 416.808 643.854 417.676 640.439 417.99L640.499 408.398C640.499 408.398 662.274 400.671 674.216 405.588C686.158 410.505 722.685 432.281 722.685 432.281L720.577 437.198Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_16"
              d="M680.478 338.505C680.478 338.505 699.444 316.729 705.063 303.383C708.619 295.292 715.145 288.874 723.295 285.456C723.295 285.456 765.474 294.953 764.771 311.812C764.069 328.671 738.078 354.661 738.078 354.661C738.078 354.661 741.591 448.086 722.625 446.681C718.534 441.799 715.647 436.025 714.195 429.823C712.088 419.988 646.761 409.452 646.761 409.452C646.761 409.452 641.141 419.286 640.439 415.071C639.736 410.857 646.058 307.597 646.058 307.597L679.556 285.456L680.478 338.505Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_17"
              d="M759.36 305.026L759.831 305.29C761.332 306.133 762.582 307.36 763.451 308.845C764.321 310.331 764.779 312.021 764.779 313.743L764.772 365.873C764.777 367.29 765.091 368.689 765.691 369.973C766.291 371.257 767.163 372.394 768.247 373.307C770.953 375.609 773.462 378.925 768.284 380.652C759.854 383.461 726.839 397.51 726.137 392.593C725.435 387.676 724.03 382.056 726.137 382.056C727.787 382.056 737.616 331.685 745.515 310.149C745.997 308.83 746.76 307.632 747.751 306.638C748.742 305.643 749.937 304.876 751.254 304.389C752.571 303.901 753.978 303.706 755.377 303.816C756.777 303.926 758.136 304.339 759.36 305.026V305.026Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_18"
              d="M655.541 305.139L645.707 307.949L634.468 356.417C634.468 356.417 621.122 359.227 625.336 364.144C629.551 369.061 647.112 381.705 647.112 381.705L655.541 305.139Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_19"
              d="M711.855 205.718C709.652 202.761 706.799 200.351 703.515 198.675C700.23 196.999 696.604 196.103 692.918 196.055H692.019C674.696 196.055 660.654 211.73 660.654 231.066V231.066H666.458L667.395 223.931L668.77 231.066H703.196L706.08 225.03L705.359 231.066H712.131C715.291 246.761 710.751 262.456 698.509 278.15H710.046L715.814 266.078L714.372 278.15H736.364L740.69 250.383C740.69 229.587 728.607 211.953 711.855 205.718Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_20"
              d="M123.535 529.728C130.925 513.639 136.122 475.501 136.122 431.001C136.122 371.963 118.806 313.209 107.525 313.209H73.4811V746.248H107.525V548.793H243.701V746.248H277.745V529.728H123.535ZM107.525 528.979C107.635 529.231 107.745 529.486 107.856 529.728H107.525V528.979Z"
              fill="#7A5217"
            />
            <path
              id="Vector_21"
              d="M265.433 450.728C265.433 450.728 306.433 467.728 293.433 477.728C280.433 487.728 253.433 465.728 254.433 462.728C255.433 459.728 250.433 452.728 250.433 452.728L265.433 450.728Z"
              fill="#9F616A"
            />
            <path
              id="Vector_22"
              d="M229.433 316.728L242.433 328.728L245.433 363.728L255.433 400.728C255.433 400.728 272.433 435.728 269.433 442.728C266.433 449.728 272.433 451.728 272.433 451.728L249.433 460.728C249.433 460.728 224.433 432.728 224.433 426.728C224.433 420.728 229.433 316.728 229.433 316.728Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_23"
              d="M352.433 676.728C352.433 676.728 347.433 699.728 346.433 701.728C345.433 703.728 365.433 710.728 365.433 710.728L389.433 693.728C389.433 693.728 379.433 671.728 379.433 667.728L352.433 676.728Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_24"
              d="M221.433 452.728L228.433 460.728C228.433 460.728 373.433 478.728 376.433 506.728C379.433 534.728 400.433 666.728 388.433 670.728C376.433 674.728 345.433 689.728 345.433 681.728C345.433 673.728 325.433 563.728 329.433 543.728C329.433 543.728 156.433 521.728 152.433 496.728C148.433 471.728 156.433 460.728 156.433 460.728L221.433 452.728Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_25"
              opacity="0.1"
              d="M221.433 452.728L228.433 460.728C228.433 460.728 373.433 478.728 376.433 506.728C379.433 534.728 400.433 666.728 388.433 670.728C376.433 674.728 345.433 689.728 345.433 681.728C345.433 673.728 325.433 563.728 329.433 543.728C329.433 543.728 156.433 521.728 152.433 496.728C148.433 471.728 156.433 460.728 156.433 460.728L221.433 452.728Z"
              fill="black"
            />
            <path
              id="Vector_26"
              d="M350.433 699.728C350.433 699.728 346.433 690.728 344.433 693.728C342.433 696.728 335.433 731.728 342.433 731.728C349.433 731.728 413.433 747.728 422.433 745.728C431.433 743.728 448.433 746.728 446.433 736.728C444.433 726.728 434.433 725.728 434.433 725.728C434.433 725.728 397.433 703.728 395.433 692.728C393.433 681.728 382.433 687.728 382.433 687.728V694.728C382.433 694.728 361.433 710.728 350.433 699.728Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_27"
              d="M160.433 240.728C160.433 240.728 167.433 274.728 152.433 277.728C137.433 280.728 171.433 300.728 171.433 300.728L193.433 312.728L209.433 296.728C209.433 296.728 194.933 267.228 205.933 245.228C216.933 223.228 160.433 240.728 160.433 240.728Z"
              fill="#9F616A"
            />
            <path
              id="Vector_28"
              d="M209.433 296.728L193.433 312.728L171.433 300.728C171.433 300.728 137.433 280.728 152.433 277.728C162.083 275.798 162.623 261.038 161.793 250.758C161.522 247.393 161.068 244.045 160.433 240.728C160.433 240.728 216.933 223.228 205.933 245.228C204.262 248.626 203.103 252.252 202.493 255.988C199.123 275.758 209.433 296.728 209.433 296.728Z"
              fill="#9F616A"
            />
            <path
              id="Vector_29"
              d="M265.433 705.728V728.728H303.433L306.433 719.728L302.433 695.728L265.433 705.728Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_30"
              d="M108.433 472.728C108.433 472.728 108.433 531.728 132.433 533.728C156.433 535.728 242.433 546.728 242.433 546.728C242.433 546.728 247.433 644.728 256.433 676.728C265.433 708.728 257.433 710.728 265.433 712.728C273.433 714.728 307.433 707.728 306.433 700.728C305.433 693.728 301.433 516.728 301.433 516.728C301.433 516.728 310.433 478.728 190.433 460.728L108.433 472.728Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_31"
              d="M268.433 721.728C268.433 721.728 262.433 708.728 261.433 712.728C260.433 716.728 248.433 751.728 260.433 754.728C272.433 757.728 310.433 773.728 335.433 772.728C360.433 771.728 363.433 770.728 363.433 770.728C363.433 770.728 370.433 760.728 361.433 754.728C352.433 748.728 315.433 713.728 313.433 709.728C311.433 705.728 296.433 702.728 296.433 709.728C296.433 716.728 275.433 730.728 268.433 721.728Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_32"
              opacity="0.1"
              d="M205.933 245.228C204.262 248.626 203.103 252.252 202.493 255.988C197.513 258.998 194.183 258.228 187.933 258.228C178.663 258.228 174.923 246.378 168.933 240.228C168.463 234.518 160.433 240.728 160.433 240.728C160.433 240.728 216.933 223.228 205.933 245.228Z"
              fill="black"
            />
            <path
              id="Vector_33"
              d="M185.433 257.728C203.659 257.728 218.433 242.954 218.433 224.728C218.433 206.503 203.659 191.728 185.433 191.728C167.208 191.728 152.433 206.503 152.433 224.728C152.433 242.954 167.208 257.728 185.433 257.728Z"
              fill="#9F616A"
            />
            <path
              id="Vector_34"
              d="M242.433 328.728C242.433 328.728 237.433 364.728 234.433 377.728C231.433 390.728 227.433 450.728 225.433 458.728C223.433 466.728 211.433 468.728 187.433 465.728C185.927 465.539 184.411 465.425 182.893 465.388C168.113 464.918 151.203 470.448 135.553 474.788C126.553 477.288 117.963 479.388 110.433 479.728C99.0332 480.248 95.6932 467.608 96.0832 453.928C96.4532 441.198 100.063 427.548 103.433 422.728C109.503 414.048 103.533 336.118 105.773 310.418V310.408C106.113 306.488 106.643 303.788 107.433 302.728C113.433 294.728 152.433 274.728 156.803 275.538C161.163 276.348 198.433 299.728 198.433 299.728C207.433 299.728 207.143 289.968 207.143 289.968C207.143 289.968 213.433 301.728 225.433 304.728C237.433 307.728 242.433 328.728 242.433 328.728Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_35"
              d="M175.586 458.371C175.586 458.371 240.002 460.422 228.718 468.075C217.433 475.728 167.433 482.728 166.433 472.728C165.433 462.728 175.586 458.371 175.586 458.371Z"
              fill="#9F616A"
            />
            <path
              id="Vector_36"
              opacity="0.1"
              d="M182.893 465.388C183.103 469.228 172.773 477.878 169.433 483.728C167.583 486.968 153.263 482.698 135.553 474.788C121.963 468.688 108.779 461.721 96.0832 453.928C96.4532 441.198 100.063 427.548 103.433 422.728C109.503 414.048 103.533 336.118 105.773 310.418V310.408C106.307 310.137 106.862 309.91 107.433 309.728H119.433C160.433 328.728 113.433 410.728 113.433 410.728C113.433 410.728 175.433 461.728 181.433 463.728C181.827 463.803 182.184 464.007 182.448 464.307C182.713 464.608 182.869 464.989 182.893 465.388V465.388Z"
              fill="black"
            />
            <path
              id="Vector_37"
              d="M119.433 303.728H107.433C107.433 303.728 96.4332 306.728 87.4332 326.728C78.4332 346.728 45.4332 408.728 74.4332 432.728C103.433 456.728 165.433 484.728 169.433 477.728C173.433 470.728 187.433 459.728 181.433 457.728C175.433 455.728 113.433 404.728 113.433 404.728C113.433 404.728 160.433 322.728 119.433 303.728Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_38"
              d="M180.356 209.373C180.356 209.373 181.805 189.325 199.67 197.499C217.536 205.674 216.37 183.143 210.971 179.161C205.572 175.18 205.743 174.194 185.997 176.858C166.251 179.522 127.916 184.036 140.311 223.739C152.707 263.442 163.933 253.228 163.933 253.228C163.933 253.228 154.08 217.501 164.064 224.307L174.047 231.114L179.315 229.998L180.356 209.373Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_39"
              d="M917.535 498.046C917.535 479.996 783.406 465.364 617.949 465.364C452.492 465.364 318.362 479.996 318.362 498.046C318.362 505.042 338.521 511.523 372.833 516.839V740.439H389.174V519.145C444.128 526.229 526.215 530.728 617.949 530.728C710.44 530.728 793.131 526.155 848.086 518.969V740.439H864.427V516.625C897.905 511.349 917.535 504.948 917.535 498.046Z"
              fill="#7A5217"
            />
            <path
              id="Vector_40"
              d="M655.481 498.728C689.17 498.728 716.481 492.013 716.481 483.728C716.481 475.444 689.17 468.728 655.481 468.728C621.792 468.728 594.481 475.444 594.481 483.728C594.481 492.013 621.792 498.728 655.481 498.728Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_41"
              d="M655.481 496.023C683.095 496.023 705.481 490.519 705.481 483.728C705.481 476.938 683.095 471.433 655.481 471.433C627.867 471.433 605.481 476.938 605.481 483.728C605.481 490.519 627.867 496.023 655.481 496.023Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_42"
              d="M428.481 510.728C462.17 510.728 489.481 504.013 489.481 495.728C489.481 487.444 462.17 480.728 428.481 480.728C394.792 480.728 367.481 487.444 367.481 495.728C367.481 504.013 394.792 510.728 428.481 510.728Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_43"
              d="M428.481 508.023C456.095 508.023 478.481 502.519 478.481 495.728C478.481 488.938 456.095 483.433 428.481 483.433C400.867 483.433 378.481 488.938 378.481 495.728C378.481 502.519 400.867 508.023 428.481 508.023Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_44"
              d="M654.061 482.669C670.122 482.669 683.142 480.613 683.142 478.078C683.142 475.542 670.122 473.486 654.061 473.486C638.001 473.486 624.981 475.542 624.981 478.078C624.981 480.613 638.001 482.669 654.061 482.669Z"
              fill="#9F616A"
            />
            <path
              id="Vector_45"
              d="M638.565 476.356C643.636 476.356 647.748 474.129 647.748 471.381C647.748 468.634 643.636 466.407 638.565 466.407C633.493 466.407 629.381 468.634 629.381 471.381C629.381 474.129 633.493 476.356 638.565 476.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_46"
              d="M635.121 476.356C640.193 476.356 644.304 474.129 644.304 471.381C644.304 468.634 640.193 466.407 635.121 466.407C630.049 466.407 625.938 468.634 625.938 471.381C625.938 474.129 630.049 476.356 635.121 476.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_47"
              d="M675.298 477.504C680.37 477.504 684.481 475.277 684.481 472.529C684.481 469.782 680.37 467.555 675.298 467.555C670.226 467.555 666.115 469.782 666.115 472.529C666.115 475.277 670.226 477.504 675.298 477.504Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_48"
              d="M654.253 476.356C659.325 476.356 663.436 474.129 663.436 471.381C663.436 468.634 659.325 466.407 654.253 466.407C649.181 466.407 645.069 468.634 645.069 471.381C645.069 474.129 649.181 476.356 654.253 476.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_49"
              d="M669.941 476.356C675.013 476.356 679.124 474.129 679.124 471.381C679.124 468.634 675.013 466.407 669.941 466.407C664.869 466.407 660.758 468.634 660.758 471.381C660.758 474.129 664.869 476.356 669.941 476.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_50"
              d="M681.803 468.32C665.206 473.952 646.584 473.952 625.938 468.32V467.545C625.934 461.69 628.182 456.057 632.215 451.813C636.248 447.568 641.759 445.036 647.607 444.741C652.529 444.493 657.46 444.5 662.381 444.76C667.643 445.066 672.587 447.376 676.199 451.213C679.811 455.051 681.816 460.126 681.803 465.397V468.32Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_51"
              d="M673.654 489.406C661.389 490.645 649.03 490.645 636.764 489.406C633.855 489.121 631.155 487.764 629.19 485.6C627.225 483.436 626.134 480.618 626.129 477.695H684.29C684.285 480.618 683.194 483.436 681.229 485.6C679.264 487.764 676.564 489.121 673.654 489.406V489.406Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_52"
              d="M646.217 451.867C644.884 452.423 643.453 452.709 642.008 452.709C640.564 452.709 639.133 452.423 637.799 451.867C639.133 451.311 640.564 451.025 642.008 451.025C643.453 451.025 644.884 451.311 646.217 451.867V451.867Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_53"
              d="M654.635 455.311C653.302 455.866 651.871 456.152 650.426 456.152C648.982 456.152 647.551 455.866 646.217 455.311C647.551 454.755 648.982 454.469 650.426 454.469C651.871 454.469 653.302 454.755 654.635 455.311V455.311Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_54"
              d="M655.401 449.189C654.067 449.744 652.636 450.03 651.192 450.03C649.747 450.03 648.316 449.744 646.983 449.189C648.316 448.633 649.747 448.347 651.192 448.347C652.636 448.347 654.067 448.633 655.401 449.189V449.189Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_55"
              d="M671.089 449.189C669.755 449.744 668.325 450.03 666.88 450.03C665.435 450.03 664.004 449.744 662.671 449.189C664.004 448.633 665.435 448.347 666.88 448.347C668.325 448.347 669.755 448.633 671.089 449.189V449.189Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_56"
              d="M666.88 452.632C665.546 453.188 664.116 453.474 662.671 453.474C661.226 453.474 659.795 453.188 658.462 452.632C659.795 452.077 661.226 451.79 662.671 451.79C664.116 451.79 665.546 452.077 666.88 452.632V452.632Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_57"
              d="M428.061 494.669C444.122 494.669 457.142 492.613 457.142 490.078C457.142 487.542 444.122 485.486 428.061 485.486C412.001 485.486 398.981 487.542 398.981 490.078C398.981 492.613 412.001 494.669 428.061 494.669Z"
              fill="#9F616A"
            />
            <path
              id="Vector_58"
              d="M412.565 488.356C417.636 488.356 421.748 486.129 421.748 483.381C421.748 480.634 417.636 478.407 412.565 478.407C407.493 478.407 403.381 480.634 403.381 483.381C403.381 486.129 407.493 488.356 412.565 488.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_59"
              d="M409.121 488.356C414.193 488.356 418.304 486.129 418.304 483.381C418.304 480.634 414.193 478.407 409.121 478.407C404.049 478.407 399.938 480.634 399.938 483.381C399.938 486.129 404.049 488.356 409.121 488.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_60"
              d="M449.298 489.504C454.37 489.504 458.481 487.277 458.481 484.529C458.481 481.782 454.37 479.555 449.298 479.555C444.226 479.555 440.115 481.782 440.115 484.529C440.115 487.277 444.226 489.504 449.298 489.504Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_61"
              d="M428.253 488.356C433.325 488.356 437.436 486.129 437.436 483.381C437.436 480.634 433.325 478.407 428.253 478.407C423.181 478.407 419.07 480.634 419.07 483.381C419.07 486.129 423.181 488.356 428.253 488.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_62"
              d="M443.941 488.356C449.013 488.356 453.124 486.129 453.124 483.381C453.124 480.634 449.013 478.407 443.941 478.407C438.869 478.407 434.758 480.634 434.758 483.381C434.758 486.129 438.869 488.356 443.941 488.356Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_63"
              d="M455.803 480.32C439.206 485.952 420.584 485.952 399.938 480.32V479.545C399.934 473.69 402.182 468.057 406.215 463.813C410.248 459.568 415.759 457.036 421.607 456.741C426.529 456.493 431.46 456.5 436.381 456.76C441.643 457.066 446.587 459.376 450.199 463.213C453.81 467.051 455.816 472.126 455.803 477.397V480.32Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_64"
              d="M447.654 501.406C435.389 502.645 423.03 502.645 410.764 501.406C407.855 501.121 405.155 499.764 403.19 497.6C401.225 495.436 400.134 492.618 400.129 489.695H458.29C458.285 492.618 457.194 495.436 455.229 497.6C453.264 499.764 450.564 501.121 447.654 501.406V501.406Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_65"
              d="M420.217 463.867C418.884 464.423 417.453 464.709 416.008 464.709C414.564 464.709 413.133 464.423 411.799 463.867C413.133 463.311 414.564 463.025 416.008 463.025C417.453 463.025 418.884 463.311 420.217 463.867Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_66"
              d="M428.635 467.311C427.302 467.866 425.871 468.152 424.426 468.152C422.982 468.152 421.551 467.866 420.217 467.311C421.551 466.755 422.982 466.469 424.426 466.469C425.871 466.469 427.302 466.755 428.635 467.311Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_67"
              d="M429.401 461.189C428.067 461.744 426.637 462.03 425.192 462.03C423.747 462.03 422.316 461.744 420.983 461.189C422.316 460.633 423.747 460.347 425.192 460.347C426.637 460.347 428.067 460.633 429.401 461.189V461.189Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_68"
              d="M445.089 461.189C443.755 461.744 442.325 462.03 440.88 462.03C439.435 462.03 438.004 461.744 436.671 461.189C438.004 460.633 439.435 460.347 440.88 460.347C442.325 460.347 443.755 460.633 445.089 461.189Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_69"
              d="M440.88 464.632C439.546 465.188 438.116 465.474 436.671 465.474C435.226 465.474 433.795 465.188 432.462 464.632C433.795 464.077 435.226 463.79 436.671 463.79C438.116 463.79 439.546 464.077 440.88 464.632V464.632Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_70"
              d="M707.958 479.664L708.099 482.761L708.278 486.779C708.278 486.779 689.826 487.822 686.109 491.75C686.023 491.834 685.944 491.925 685.872 492.022L678.481 490.404L678.463 486.763C678.463 486.763 678.565 486.707 678.763 486.6C678.808 486.576 678.865 486.546 678.922 486.516C687.966 482.088 697.888 479.746 707.958 479.664Z"
              fill="#F4E23B"
            />
            <path
              id="Vector_71"
              opacity="0.1"
              d="M707.958 479.664L708.099 482.761C699.916 483.014 684.431 487.892 684.431 487.892L678.763 486.6C687.846 482.113 697.827 479.742 707.958 479.664V479.664Z"
              fill="black"
            />
            <path
              id="Vector_72"
              opacity="0.1"
              d="M678.922 486.516L684.431 487.892L686.109 491.75C686.023 491.834 685.944 491.925 685.872 492.022L678.481 490.404L678.463 486.763C678.463 486.763 678.565 486.707 678.763 486.6C678.808 486.576 678.865 486.546 678.922 486.516Z"
              fill="white"
            />
            <path
              id="Vector_73"
              d="M484.958 492.664L485.099 495.761L485.278 499.779C485.278 499.779 466.826 500.822 463.109 504.75C463.023 504.834 462.944 504.925 462.872 505.022L455.481 503.404L455.463 499.763C455.463 499.763 455.565 499.707 455.763 499.6C455.808 499.576 455.865 499.546 455.922 499.516C464.966 495.088 474.888 492.746 484.958 492.664Z"
              fill="#F4E23B"
            />
            <path
              id="Vector_74"
              opacity="0.1"
              d="M484.958 492.664L485.099 495.761C476.916 496.014 461.431 500.892 461.431 500.892L455.763 499.6C464.846 495.113 474.828 492.742 484.958 492.664Z"
              fill="black"
            />
            <path
              id="Vector_75"
              opacity="0.1"
              d="M455.922 499.516L461.431 500.892L463.109 504.75C463.023 504.834 462.944 504.925 462.872 505.022L455.481 503.404L455.463 499.763C455.463 499.763 455.565 499.707 455.763 499.6C455.808 499.576 455.865 499.546 455.922 499.516Z"
              fill="white"
            />
            <path
              id="Vector_76"
              d="M819.087 386.884C817.512 385.311 815.667 384.036 813.639 383.12V344.264C813.639 343.808 813.458 343.371 813.136 343.049C812.814 342.727 812.377 342.546 811.921 342.546H802.302C801.846 342.546 801.409 342.727 801.087 343.049C800.765 343.371 800.584 343.808 800.584 344.264V382.375C797.107 383.528 794.081 385.747 791.936 388.717C789.792 391.687 788.637 395.257 788.636 398.921V482.273C788.636 483.276 788.833 484.269 789.217 485.196C789.601 486.122 790.163 486.964 790.873 487.674C791.582 488.383 792.424 488.945 793.351 489.329C794.277 489.713 795.271 489.911 796.274 489.911H816.878C818.895 489.911 820.828 489.11 822.254 487.684C823.68 486.258 824.481 484.324 824.481 482.308V399.903C824.484 397.485 824.009 395.089 823.083 392.855C822.157 390.621 820.799 388.591 819.087 386.884Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_77"
              d="M824.481 411.139H788.636V458.491H824.481V411.139Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_78"
              d="M814.413 345.201H813.639V344.264C813.639 343.808 813.458 343.371 813.136 343.049C812.814 342.727 812.377 342.546 811.921 342.546H802.302C801.846 342.546 801.409 342.727 801.087 343.049C800.765 343.371 800.584 343.808 800.584 344.264V345.201H799.81V349.184H800.584V369.541H813.639V349.184H814.413V345.201Z"
              fill="#4BBADF"
            />
            <path
              id="Vector_79"
              d="M532.075 420.61C531.126 418.585 530.378 417.153 530.116 416.66C514.026 420.631 503.29 417.276 501.597 416.682C500.77 418.246 495.601 428.239 493.512 437.821C491.625 446.463 492.72 452.257 496.768 455.041L496.888 455.126L496.914 455.144C501.382 458.088 505.643 460.695 509.58 462.892C510.11 463.188 510.61 463.462 511.08 463.715L511.13 463.743L511.166 463.788C511.706 464.468 512.209 465.178 512.673 465.914C513.542 467.13 514.07 468.557 514.201 470.047V512.971L513.93 512.976C503.667 513.186 497.314 514.926 497.314 516.417C497.314 518.054 504.932 519.878 515.862 519.878C526.793 519.878 534.41 518.054 534.41 516.417C534.41 514.948 528.165 513.217 518.076 512.984L517.804 512.978L518.058 473.273C518.064 470.584 518.689 467.934 519.884 465.526C520.088 465.121 520.307 464.727 520.535 464.357C520.718 464.058 520.912 463.767 521.111 463.492L521.148 463.441L521.203 463.411C521.708 463.135 522.245 462.836 522.813 462.514L522.879 462.478C526.605 460.374 530.619 457.907 534.812 455.143C538.555 452.675 539.858 447.688 538.686 440.319C537.568 433.293 534.474 425.723 532.075 420.61Z"
              fill="#D0CDE1"
            />
            <path
              id="Vector_80"
              d="M590.454 380.144C589.505 378.12 588.756 376.687 588.494 376.195C572.404 380.165 561.669 376.81 559.976 376.217C559.148 377.78 553.979 387.774 551.891 397.355C550.003 405.997 551.099 411.791 555.146 414.575L555.266 414.661L555.292 414.678C559.76 417.622 564.022 420.229 567.958 422.427C568.489 422.722 568.989 422.996 569.459 423.25L569.509 423.277L569.544 423.322C570.085 424.003 570.588 424.712 571.051 425.448C571.921 426.664 572.449 428.091 572.58 429.581V472.505L572.308 472.511C562.045 472.72 555.692 474.46 555.692 475.952C555.692 477.588 563.31 479.412 574.241 479.412C585.171 479.412 592.789 477.588 592.789 475.952C592.789 474.482 586.544 472.752 576.455 472.519L576.182 472.512L576.436 432.807C576.442 430.119 577.067 427.468 578.262 425.06C578.466 424.655 578.685 424.262 578.913 423.891C579.096 423.592 579.29 423.301 579.49 423.026L579.526 422.976L579.581 422.946C580.086 422.67 580.623 422.371 581.191 422.048L581.258 422.012C584.983 419.908 588.997 417.441 593.19 414.677C596.933 412.21 598.237 407.222 597.064 399.853C595.946 392.827 592.852 385.257 590.454 380.144Z"
              fill="#D0CDE1"
            />
          </g>
          <g id="wine">
            <path
              id="Vector_81"
              d="M533.037 454.243C528.641 457.141 525.012 459.313 522.237 460.879H522.234C518.185 460.603 514.119 460.717 510.093 461.22C507.248 459.632 503.409 457.355 498.688 454.243C498.643 454.213 498.598 454.184 498.553 454.151V454.149C494.222 451.169 494.115 444.86 495.524 438.408C499.108 438.01 504.823 438.184 512.502 440.927C521.594 444.175 531.113 442.338 536.628 440.668C537.534 446.36 536.982 451.642 533.037 454.243Z"
              fill="#A00C27"
            />
            <path
              id="Vector_82"
              d="M591.415 413.778C587.019 416.675 583.39 418.848 580.615 420.413H580.613C576.564 420.137 572.498 420.251 568.471 420.754C565.626 419.166 561.788 416.889 557.066 413.778C557.021 413.748 556.977 413.718 556.932 413.685V413.683C552.601 410.704 552.494 404.394 553.903 397.942C557.487 397.544 563.202 397.718 570.88 400.461C579.973 403.709 589.491 401.872 595.007 400.202C595.913 405.895 595.36 411.176 591.415 413.778Z"
              fill="#A00C27"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="998.282" height="772.773" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Table;
